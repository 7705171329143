.catalog-product-view {
    .product-info-price {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .special-price,
    .price-box {
        margin: 0;
    }
}

.traffic-light {
    @include lib-icon-font(
        $_icon-font-content: $icon-success,
        $_icon-font-color: $theme-color-green3,
        $_icon-font-margin: 0 5px 0 0,
        $_icon-font-size: 14px
    );
    align-items: center;
    margin: 20px 0 5px;
    display: flex;

    &:before {
        display: inline-flex;
    }

    &__text {
        position: relative;
        font-size: 13px;
        line-height: 1.2;
        font-weight: $font-weight__medium;
        display: inline-flex;

        &--in-stock {
            color: $theme-color-green3;
        }

        &--out-of-stock {
            color: $color-red10;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .product-info-price {
            margin-top: 20px;
        }
    }

    .traffic-light {
        display: flex;
        align-items: center;

        @include lib-icon-font(
            $_icon-font-content: $icon-success,
            $_icon-font-color: $theme-color-green3,
            $_icon-font-margin: -3px 5px 0 0,
            $_icon-font-size: 14px
        );
    }
}
