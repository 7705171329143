//  PayPal checkout button
.paypal {
    &.after {
        &::before {
            content: '- ' attr(data-label) ' -';
            display: block;
            margin: $indent__xs $indent__xs $indent__s;
            text-transform: uppercase;
        }
    }

    &.before {
        &::before {
            content: '- ' attr(data-label) ' -';
            display: block;
            margin: $indent__s $indent__xs;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &.acceptance {
        display: block;
        margin: 0 0 $indent__base;

        img {
            max-width: 100%;
        }
    }
}

.box-tocart,
.block-minicart {
    .paypal img {
        display: block;
        margin: 0 auto;
    }
}

.paypal-button-widget {
    .paypal-button {
        @extend .abs-action-button-as-link;
    }
}
