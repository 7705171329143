.sidebar-categories {
    &__title {
        margin-bottom: $h4__margin-bottom + 10px;
    }

    &__list {
        @include lib-list-reset-styles();
    }

    &__anchor {
        @include lib-link-all(
            $_link-color: $text__color
        );
    }

    &__show-more {
        @include lib-button-as-link();
    }

    &__list:not(.active) &__list-item--outside-limit {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .sidebar-categories {
        display: none;
    }
}
