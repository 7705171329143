.header-chevron {
    @include lib-icon-font(
        $_icon-font-content: $icon-chevron-down,
        $_icon-font-position: after,
        $_icon-font-margin: 0 0 0 7px,
        $_icon-font-size: 11px,
        $_icon-font-color: false,
        $_icon-font-color-hover: false,
        $_icon-font-color-active: false,
        $_icon-font-display: false
    );
    
    &.active {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-chevron-up,
            $_icon-font-position: after
        );
    }
}

.header-middle-bar {
    @include lib-css(background-color, $color-white);
    
    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    &__logo {
        display: flex;
        align-items: center;
        max-width: 45%;
    }
    
    &__header-icons {
        display: flex;
        margin-left: 10px;
    }
    
    &__nav-toggle {
        padding-right: 10px;
    }
    
    &__user.in-header {
        @include lib-list-reset-styles();
        flex-direction: row-reverse;
        justify-content: space-aroudn;
        margin-left: 25px;
        
        > li {
            margin: 0;
            
            > a {
                font-size: 13px;
                letter-spacing: .06em;
                font-weight: $font-weight__semibold;
            }
        }
    }
    
    .action.showlogin,
    .customer-name {
        @extend .header-chevron;
    }
    
    .action.showcart {
        position: relative;
        white-space: nowrap;
        
        .counter.qty {
            @extend .abs-number-badge;
            
            &.empty {
                display: none;
            }
            
            .loader {
                > img {
                    @include lib-css(max-width, $minicart-qty__height);
                }
            }
        }
        
        .counter-label {
            @extend .abs-visually-hidden;
        }
    }
    
    .header-action {
        // .header-action is inside .header-middle-bar because we don't want this in the off-canvas menu
        @include lib-link-all(
            $_link-color: $brand__primary__color
        );
        @include lib-css(border, $middle-bar__action__border);
        position: relative;
        z-index: 105;
        display: flex;
        align-items: center;
        height: 45px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 4px;
        border-width: 1px 1px 0 1px;
        
        &:hover {
            text-decoration: none;
        }
        
        &.active {
            @include lib-css(border-color, $middle-bar__action__border-color-active);
            background-color: $color-white;
            color: $middle-bar__action__color-active;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    
    .phonenumber {
        @include lib-link-all(
            $_link-color: $brand__primary__color
        );
        @include lib-icon-font(
            $_icon-font-content: $icon-phone-alt-solid,
            $_icon-font-position: before,
            $_icon-font-margin: 0 0 0 7px,
            $_icon-font-size: 11px
        );
        font-weight: $font-weight__medium;
    }
}

.minicart-wrapper,
.login-wrapper,
.customer-welcome {
    &.active::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        box-shadow: 3px 0px 2px -2px rgba(49, 49, 49, 0.1);
    }
}

@include min-screen($screen__m) {
    .header-middle-bar {
        &__search {
            flex: 1;
        }
    }
}

@include min-screen($screen__l) {
    .header-middle-bar {
        &__user.in-header {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
}

@include max-screen($screen__m) {
    .header-middle-bar {
        &__inner {
            padding: 10px;
        }
    
        &__user.in-header {
            display: block;
        
            .login-wrapper {
                display: none;
            }
        
            span {
                display: none;
            }
        }
    
        .phonenumber {
            position: absolute;
            right: 60px;
            top: 20px;
        
            &:before {
                font-size: 17px;
                color: $color-white;
            }
        }
        
        &__search {
            width: 100%;
            order: 4;
        }
        
        &__nav-toggle,
        &__header-icons {
            flex: 1;
        }
        
        &__header-icons {
            justify-content: flex-end;
        }
        
        .block-search {
            margin-top: 10px;
        }
        
        &__logo {
            margin-top: 15px;
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 640px) {
    .header-middle-bar {
        .phonenumber {
            top: 29px;
        }
    }
}
