//
//  Popups
//  _____________________________________________

@mixin lib-popup(
    $_popup-width                                              : $popup__width,
    $_popup-height                                             : $popup__height,
    $_popup-padding                                            : $popup__padding,
    $_popup-background                                         : $popup__background,
    $_popup-border                                             : $popup__border,
    $_popup-shadow                                             : $popup__shadow,
    $_popup-fade                                               : $popup__fade,
    $_popup-z-index                                            : $popup__z-index,

    $_popup-position                                           : $popup__position,
    $_popup-position-top                                       : $popup__position-top,
    $_popup-position-right                                     : $popup__position-right,
    $_popup-position-bottom                                    : $popup__position-bottom,
    $_popup-position-left                                      : $popup__position-left,

    $_popup-margin-top                                         : $popup__margin-top,
    $_popup-margin-right                                       : $popup__margin-right,
    $_popup-margin-bottom                                      : $popup__margin-bottom,
    $_popup-margin-left                                        : $popup__margin-left,

    $_popup-content-height                                     : $popup-content__height,
    $_popup-header-margin                                      : $popup-header__margin,
    $_popup-content-margin                                     : $popup-content__margin,
    $_popup-footer-margin                                      : $popup-footer__margin,

    $_popup-title-headings                                     : $popup-title-headings,
    $_popup-title-headings-level                               : $popup-title-headings__level,

    //  Icons parameters
    $_popup-button-close-icon                                  : $popup-button-close__icon,
    $_popup-button-close-reset                                 : $popup-button-close__reset,
    $_popup-button-close-position                              : $popup-button-close__position,
    $_popup-button-close-position-top                          : $popup-button-close__position-top,
    $_popup-button-close-position-right                        : $popup-button-close__position-right,
    $_popup-button-close-position-bottom                       : $popup-button-close__position-bottom,
    $_popup-button-close-position-left                         : $popup-button-close__position-left,
    $_popup-icon-font-content                                  : $popup-icon-font__content,
    $_popup-icon-font                                          : $popup-icon-font,
    $_popup-icon-font-size                                     : $popup-icon-font__size,
    $_popup-icon-font-line-height                              : $popup-icon-font__line-height,
    $_popup-icon-font-color                                    : $popup-icon-font__color,
    $_popup-icon-font-color-hover                              : $popup-icon-font__color-hover,
    $_popup-icon-font-color-active                             : $popup-icon-font__color-active,
    $_popup-icon-font-margin                                   : $popup-icon-font__margin,
    $_popup-icon-font-vertical-align                           : $popup-icon-font__vertical-align,
    $_popup-icon-font-position                                 : $popup-icon-font__position,
    $_popup-icon-font-text-hide                                : $popup-icon-font__text-hide,

    //  Actions toolbar parameters
    $_popup-actions-toolbar                                    : $popup-actions-toolbar,
    $_popup-actions-toolbar-actions-position                   : $popup-actions-toolbar-actions__position,
    $_popup-actions-toolbar-actions-reverse                    : $popup-actions-toolbar-actions__reverse,
    $_popup-actions-toolbar-margin                             : $popup-actions-toolbar__margin,
    $_popup-actions-toolbar-padding                            : $popup-actions-toolbar__padding,
    $_popup-actions-toolbar-actions-margin                     : $popup-actions-toolbar-actions__margin,
    $_popup-actions-toolbar-primary-actions-margin             : $popup-actions-toolbar-actions-primary__margin,
    $_popup-actions-toolbar-secondary-actions-margin           : $popup-actions-toolbar-actions-secondary__margin,
    $_popup-actions-toolbar-actions-links-margin-top           : $popup-actions-toolbar-actions-links__margin-top,
    $_popup-actions-toolbar-primary-actions-links-margin-top   : $popup-actions-toolbar-actions-links-primary__margin-top,
    $_popup-actions-toolbar-secondary-actions-links-margin-top : $popup-actions-toolbar-actions-links-secondary__margin-top
) {
    @include lib-css(background, $_popup-background);
    @include lib-css(border, $_popup-border);
    @include lib-css(padding, $_popup-padding);
    @include lib-css(width, $_popup-width);
    @include _lib-popup-height($_popup-height);
    @include _lib-popup-shadow($_popup-shadow);
    @include _lib-popup-action-toolbar(
        $_popup-actions-toolbar,
        $_popup-actions-toolbar-actions-position,
        $_popup-actions-toolbar-actions-reverse,
        $_popup-actions-toolbar-margin,
        $_popup-actions-toolbar-padding,
        $_popup-actions-toolbar-actions-margin,
        $_popup-actions-toolbar-primary-actions-margin,
        $_popup-actions-toolbar-secondary-actions-margin,
        $_popup-actions-toolbar-actions-links-margin-top,
        $_popup-actions-toolbar-primary-actions-links-margin-top,
        $_popup-actions-toolbar-secondary-actions-links-margin-top
    );

    .popup-header {
        @include lib-css(margin, $_popup-header-margin);

        .title {
            @include _lib-popup-headings(
                $_popup-title-headings,
                $_popup-title-headings-level
            );
        }

        @include lib-css(padding-right, $_popup-icon-font-size)
    }

    .popup-content {
        @include _lib-popup-height($_popup-content-height);
        @include lib-css(margin, $_popup-content-margin);
    }

    .popup-footer {
        @include lib-css(margin, $_popup-footer-margin);
    }

    .popup-actions {
        .action.close {
            @include lib-css(position, $_popup-button-close-position);
            @include lib-popup-button-close-icon(
                $_popup-button-close-icon,
                $_popup-icon-font-content,
                $_popup-icon-font,
                $_popup-icon-font-size,
                $_popup-icon-font-line-height,
                $_popup-icon-font-color,
                $_popup-icon-font-color-hover,
                $_popup-icon-font-color-active,
                $_popup-icon-font-margin,
                $_popup-icon-font-vertical-align,
                $_popup-icon-font-position,
                $_popup-icon-font-text-hide
            );
            @include _lib-popup-button-close-reset(
                $_popup-button-close-reset
            );
            @include lib-css(bottom, $_popup-button-close-position-bottom);
            @include lib-css(left, $_popup-button-close-position-left);
            @include lib-css(right, $_popup-button-close-position-right);
            @include lib-css(top, $_popup-button-close-position-top);
        }
    }

    @include _lib-popup-fade($_popup-fade);

    @include lib-css(bottom, $_popup-position-bottom);
    @include lib-css(left, $_popup-position-left);
    @include lib-css(margin-bottom, $_popup-margin-bottom);
    @include lib-css(margin-left, $_popup-margin-left);
    @include lib-css(margin-right, $_popup-margin-right);
    @include lib-css(margin-top, $_popup-margin-top);
    @include lib-css(position, $_popup-position);
    @include lib-css(right, $_popup-position-right);
    @include lib-css(top, $_popup-position-top);
    @include lib-css(z-index, $_popup-z-index);
    display: none;
    opacity: 0;

    &.active {
        opacity: 1;
    }
}

@mixin lib-window-overlay(
    $_overlay-background: $overlay__background,
    $_overlay-opacity: $overlay__opacity,
    $_overlay-opacity-old: $overlay__opacity-old,
    $_overlay-fade: $overlay__fade,
    $_overlay-z-index: $overlay__z-index
) {
    @include _lib-popup-fade($_overlay-fade);
    @include lib-css(background, $_overlay-background);
    @include lib-css(z-index, $_overlay-z-index);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;

    &.active {
        @include lib-css(opacity, $_overlay-opacity);

        @if $_overlay-opacity-old != false {
            filter: alpha(opacity=$_overlay-opacity-old);
        }
    }
}

//
//  Internal use mixins
//  ---------------------------------------------

//  Popup fade
@mixin _lib-popup-fade($_popup-fade) {
    @if $_popup-fade != '' {
        @include lib-css(transition, $_popup-fade);
    }
}

//  Popup shadow
@mixin _lib-popup-shadow($_popup-shadow) {
    @if $_popup-shadow != '' and $_popup-shadow != none {
        @include lib-css(box-shadow, $_popup-shadow);
    }
}

//  Popup height
@mixin _lib-popup-height($_popup-height) {
    @if $_popup-height != auto {
        overflow-y: auto;
        @include lib-css(max-height, $_popup-height);
    }
}

//  Popup Headings
@mixin _lib-popup-headings(
    $_popup-title-headings,
    $_popup-title-headings-level
) {
    @if $_popup-title-headings == true {
        @include lib-heading($_popup-title-headings-level);
    }
}

//  Popup action-toolbar
@mixin _lib-popup-action-toolbar(
    $_popup-actions-toolbar,
    $_popup-actions-toolbar-actions-position,
    $_popup-actions-toolbar-actions-reverse,
    $_popup-actions-toolbar-margin,
    $_popup-actions-toolbar-padding,
    $_popup-actions-toolbar-actions-margin,
    $_popup-actions-toolbar-primary-actions-margin,
    $_popup-actions-toolbar-secondary-actions-margin,
    $_popup-actions-toolbar-actions-links-margin-top,
    $_popup-actions-toolbar-primary-actions-links-margin-top,
    $_popup-actions-toolbar-secondary-actions-links-margin-top
) {
    @if $_popup-actions-toolbar == true {
        .popup-footer {
            .actions.toolbar {
                @include lib-actions-toolbar(
                    $_actions-toolbar-actions-position                   : $_popup-actions-toolbar-actions-position,
                    $_actions-toolbar-actions-reverse                    : $_popup-actions-toolbar-actions-reverse,
                    $_actions-toolbar-margin                             : $_popup-actions-toolbar-margin,
                    $_actions-toolbar-padding                            : $_popup-actions-toolbar-padding,
                    $_actions-toolbar-actions-margin                     : $_popup-actions-toolbar-actions-margin,
                    $_actions-toolbar-primary-actions-margin             : $_popup-actions-toolbar-primary-actions-margin,
                    $_actions-toolbar-secondary-actions-margin           : $_popup-actions-toolbar-secondary-actions-margin,
                    $_actions-toolbar-actions-links-margin-top           : $_popup-actions-toolbar-actions-links-margin-top,
                    $_actions-toolbar-primary-actions-links-margin-top   : $_popup-actions-toolbar-primary-actions-links-margin-top,
                    $_actions-toolbar-secondary-actions-links-margin-top : $_popup-actions-toolbar-secondary-actions-links-margin-top
                );
            }
        }
    }
}

//  Popup close button use icon
@mixin lib-popup-button-close-icon(
    $_popup-button-close-icon,
    $_popup-icon-font-content,
    $_popup-icon-font,
    $_popup-icon-font-size,
    $_popup-icon-font-line-height,
    $_popup-icon-font-color,
    $_popup-icon-font-color-hover,
    $_popup-icon-font-color-active,
    $_popup-icon-font-margin,
    $_popup-icon-font-vertical-align,
    $_popup-icon-font-position,
    $_popup-icon-font-text-hide
) {
    @if $_popup-button-close-icon == true {
        @include lib-button-icon(
            $_icon-font-content        : $_popup-icon-font-content,
            $_icon-font                : $_popup-icon-font,
            $_icon-font-size           : $_popup-icon-font-size,
            $_icon-font-line-height    : $_popup-icon-font-line-height,
            $_icon-font-color          : $_popup-icon-font-color,
            $_icon-font-color-hover    : $_popup-icon-font-color-hover,
            $_icon-font-color-active   : $_popup-icon-font-color-active,
            $_icon-font-margin         : $_popup-icon-font-margin,
            $_icon-font-vertical-align : $_popup-icon-font-vertical-align,
            $_icon-font-position       : $_popup-icon-font-position,
            $_icon-font-text-hide      : $_popup-icon-font-text-hide
        );
    }
}
//  Popup close button reset
@mixin _lib-popup-button-close-reset (
    $_popup-button-close-reset
) {
    @if $_popup-button-close-reset == true {
        @include lib-button-reset();
    }
}
