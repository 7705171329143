.product-order-list {
    &__search-container {
        @include lib-form-input-text-xl();
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-display: block,
            $_icon-font-color: $theme-icon-color-light,
            $_icon-font-size: 19px
        );
        position: relative;
        margin: 0 0 40px;
        max-width: 450px;

        input[type=text] {
            padding-left: 51px;
        }

        &::before {
            position: absolute;
            top: 7px;
            left: 16px;
        }
    }

    &__search-results {
        @extend .abs-search-results;
        max-width: 450px;
    }

    &__search-item {
        padding: 10px 15px;
        cursor: pointer;
    }

    &__product {
        width: 100%;
    }

    &__product-title {
        @extend .abs-product-link;
        @include text-truncate(17px, $fixed-height: false);
    }

    &__remove {
        @include lib-button-reset();
        @include lib-icon-text-hide();
        @include lib-icon-font(
            $_icon-font-content: $icon-remove
        );
    }

    &__image {
        max-width: none;
    }

    &__item {
        border-bottom: 1px solid $border-color__base;

        &:first-child {
            border-top: 1px solid $border-color__base;
        }
    }

    &__subtotal {
        @extend .abs-price-md;
    }

    td {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.product-order-list {
    &__body &__search {
        display: none;
    }
}

@include min-screen($screen__m) {
    .product-order-list {
        &__search-item {
            display: flex;
            justify-content: space-between;
        }
    }
}

@include max-screen($screen__m) {
    .product-order-list {
        &__item {
            display: flex;
            flex-wrap: wrap;

            > * {
                box-sizing: border-box;
            }
        }

        &__image-container {
            width: 30%;
        }

        &__image {
            max-width: 100%;
        }

        &__product {
            width: 70%;
        }
    }
}
