.timer {
    @include lib-icon-font(
        $_icon-font-content: $icon-clock,
        $_icon-font-margin: 0 10px 0 0,
        $_icon-font-size: 20px,
        $_icon-font-color: $theme-icon-color-light
    );
    display: flex;
    align-items: center;

    &__item.sec {
        strong {
            display: inline-block;
            width: 19px; // Fixed width because we don't want it to keep jumping
        }
    }

    &__divider {
        &::after {
            content: ' ';
            white-space: pre;
        }

        &.word {
            &::before {
                content: ' ';
                white-space: pre;
            }
        }
    }

    .sidebar & {
        display: none;
    }
}

@include max-screen($screen__s) {
    .timer {
        &__divider {
            display: none;
        }

        &__item {
            margin-right: 10px;
        }
    }
}
