.message.info {
    @include lib-message(info);
}

.message.error {
    @include lib-message(error);
}

.message.warning {
    @include lib-message(warning);
}

.message.notice {
    @include lib-message(notice);
}

.message.success {
    @include lib-message(success);
}
