/*!
 * Based on Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 */

// We don't want to add this to everything since Magento 2 wasn't built with this in mind.
.container,
.container-fluid,
.grid-row,
.grid-col,
[class*=grid-col-] {
    box-sizing: border-box;
}

@import "functions";
@import "variables";

@import "mixins/breakpoints";
@import "mixins/grid-framework";
@import "mixins/grid";

@import "grid";
@import "utilities/display";
@import "utilities/flex";

@import "utilities/spacing";
