.top-categories {
    margin-bottom: 20px;
}

.badges {
    @include lib-list-reset-styles();
    display: flex;
    flex-wrap: wrap;

    &__badge {
        margin-right: 10px;
    }

    &__anchor {
        @include lib-link(
            $_link-color                   : $brand__primary__color,
            $_link-color-visited           : $brand__primary__color,
            $_link-color-hover             : $brand__primary__color,
            $_link-color-active            : $brand__primary__color
        );
        display: block;
        padding: 8px 15px;
        border-radius: 20px;
        border: 1px solid $border-color__base;
        font-weight: $font-weight__medium;
        line-height: 1;

        &:hover {
            text-decoration: none;
            border-color: darken($border-color__base, 5%);
        }
    }
}

@include max-screen($screen__m) {
    .top-categories {
        display: none;
    }
}
