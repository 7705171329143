.product-pros-cons-container {
    margin-bottom: 20px;
}

.product-pros-cons {
    &__list {
        @extend .abs-icon-list;

        &--pros li {
            @include lib-icon-font(
                $_icon-font-content: $icon-pro,
                $_icon-font-display: block,
                $_icon-font-margin: -1px 0 0 0,
                $_icon-font-color: $accept__color,
                $_icon-font-size: 16px
            );
        }

        &--cons li {
            @include lib-icon-font(
                $_icon-font-content: $icon-con,
                $_icon-font-display: block,
                $_icon-font-margin: -1px 0 0 0,
                $_icon-font-color: $theme-icon-color-light,
                $_icon-font-size: 16px
            );
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-pros-cons-container {
        margin-bottom: 60px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-pros-cons-container {
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
        padding-bottom: 20px;
        border-bottom: 1px solid $border-color__base;
    }
}
