.category-grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
    
    &__item {
        width: 20%;
        margin-bottom: 30px;
    }
    
    &__anchor {
        @include lib-link-all(
            $_link-color: $text__color
        );
        font-weight: $font-weight__medium;
    }
    
    &__details {
        text-align: center;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .category-grid {
        &__item {
            width: 50%;
        }
    }
}
