.actions-toolbar {
    > .primary,
    > .secondary {
        text-align: center;
        margin-bottom: $indent__s;

        .action {
            width: 100%;
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    > .secondary {
        .action.back {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .actions-toolbar {
        @include lib-actions-toolbar();

        > .secondary,
        > .primary {
            margin-bottom: 0;

            .action {
                margin-bottom: 0;
                width: auto;
            }
        }
    }
}
