//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

$customer-welcome__z-index: $dropdown-list__z-index + 1 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: darken($addto-color, 5%) !default;

$addto-grid-color: $icon-font__color-faded !default;
$addto-grid-hover-color: darken($addto-grid-color, 5%) !default;

body {
    @include lib-css(background-color, $page__background-color);
    
    &.navigation-active {
        .page-overlay {
            @extend .transition-visible;
        }
    }
}

.page-overlay {
    @extend .modals-overlay;
    @extend .transition-hidden;
    z-index: 2;
}


.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }
    
    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }
    
    &.noscript {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }
    
    &.cookie {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 3;
        background-color: $color-white;
        box-shadow: 0 -8px 15px 0 rgba(0, 0, 0, .1);
        
        .inner {
            max-width: 800px;
            margin: 0 auto;
        }
        
        .content {
            box-sizing: border-box;
            display: flex;
            margin: 0 auto;
            padding: 30px 35px 35px;
            border-width: 1px 0 0 0;
            border-style: solid;
            border-color: $border-color__base;
            
            p {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-bottom: 10px;
            }
        }
        
        .actions {
            span {
                display: flex;
                align-items: center;
                color: $color-white;
                position: relative;
                padding-left: 20px;
                
                @include lib-icon-font(
                    $_icon-font-content: $icon-success,
                    $_icon-font-color: $color-white,
                    $_icon-font-size: 15px
                );
                
                &::before {
                    position: absolute;
                    top: -1px;
                    left: 0;
                }
            }
        }
    }
    
    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

.page-header {
    position: sticky;
    z-index: $z-index__header;
    width: 100%;
    top: 0;
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;
        
        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );
            
            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;
                    
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        
        li {
            font-size: $font-size__s;
            margin: 0;
        }
        
        .label {
            @extend .abs-visually-hidden;
        }
        
        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;
    
    .block-title {
        @extend .abs-block-title;
    }
    
    .block-sub-title {
        @extend .abs-block-sub-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Loader
//  ---------------------------------------------

.blueprint-loader {
    img {
        animation: rotate-loader 0.7s infinite;
    }
}

.loading-mask {
    .loader {
        img {
            animation: rotate-loader 0.7s infinite;
        }
    }
}

@keyframes rotate-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

//
//  Sidebar list
//  ---------------------------------------------

.sidebar-list {
    @include abs-sidebar-list();
}

//
//  Contact form
//  ---------------------------------------------

.form.contact {
    @extend .abs-margin-for-blocks-and-widgets;
}

//
//  Intro
//  ---------------------------------------------

.intro-text {
    text-align: center;
    background-color: $color-white;
}

@include min-screen($screen__m) {
    .intro-text {
        position: relative;
        max-width: 80%;
        margin: -60px auto 0;
        padding: 40px 40px 0;
    }
}

@include max-screen($screen__m) {
    .intro-image {
        margin-bottom: 40px;
    }
}

@include max-screen($screen__s) {
    .intro-image {
        display: none;
    }
}

//
//  Desktop (Cookie message max-width)
//  _____________________________________________

@include min-screen($layout__max-width - $layout-indent__width * 2) {
    .message.global {
        &.cookie {
            .content {
                border-width: 1px 1px 0 1px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }
    
    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer
        
        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }
        
        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }
    
    .navigation ul {
        padding: 0 8px;
    }
    
    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }
            
            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }
    
    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector: '.action.switch',
            $_options-selector: 'ul',
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: false,
            $_dropdown-toggle-active-icon-content: false,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 22px,
            $_dropdown-list-pointer-position: right,
            $_dropdown-list-position-right: 0,
            $_dropdown-list-z-index: $customer-welcome__z-index,
            $_icon-font-color: $color-white,
            $_icon-font-color-hover: $color-white,
            $_icon-font-color-active: $color-white,
            $_icon-font-margin: 0 0 0 4px
        );
        
        ul {
            @include lib-css(border, $middle-bar__action__dropdown-border);
            top: calc(100% - 1px);
            overflow: hidden;
            border-radius: $dropdown-list-pointer__border-radius;
        }
        
        li {
            a {
                @include lib-link(
                    $_link-color: $color-gray20,
                    $_link-text-decoration: none,
                    $_link-color-visited: $color-gray20,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $color-gray20,
                    $_link-text-decoration-hover: none,
                    $_link-color-active: $color-gray20,
                    $_link-text-decoration-active: none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }
        
        .customer-name {
            display: flex;
            cursor: pointer;
        }
        
        .customer-menu {
            display: none;
        }
        
        .action.switch {
            @include lib-button-reset();
        }
        
        .header.links {
            min-width: 175px;
        }
        
        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
            
            .customer-menu {
                display: block;
            }
        }
        
        .greet {
            display: none;
        }
    }
    
    .message.global {
        &.cookie {
            .actions {
                margin-left: $indent__s;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .message.global {
        &.cookie {
            .content {
                flex-direction: column;
            }
    
            .action.primary {
                width: 100%;
            }
            
            .actions {
                margin-top: $indent__s;
            }
        }
    }
    
    .intro-image {
        margin-bottom: 40px;
    }
}
