//
//  Typography
//  ---------------------------------------------

//  Headings
$h1__margin-bottom__desktop                                   : $indent__base !default;

//
//  Layout grid
//  ---------------------------------------------

//  Header
$header-icons-color                                           : $color-white !default;
$header-icons-color-hover                                     : $color-white !default;
$header-icons-color-active                                    : $brand__primary__color !default;

//
//  Icons
//  ---------------------------------------------
//

$icon-success: '\e60e';
$icon-error: '\e61f';
$icon-edit: '\e601';
$icon-print: '\e624';
$icon-star-empty: '\e625';
$icon-download: '\e626';
$icon-private: '\e629';
$icon-present: '\e62a';
$icon-gift-registry: '\e62b';

//
//  Popups
//  ---------------------------------------------

//  Checkout tooltip
$checkout-tooltip-icon-arrow__font-size                       : 10px !default;
$checkout-tooltip-icon__font-size                             : 21px !default;

$checkout-tooltip-content-mobile__right                       : -($indent__s) !default;
$checkout-tooltip-content-mobile__top                         : 30px + $checkout-tooltip-icon-arrow__font-size !default;
$checkout-tooltip-content__background-color                   : $color-gray-light01 !default;
$checkout-tooltip-content__padding                            : 12px !default;
$checkout-tooltip-content__border-width                       : 1px !default;

$checkout-tooltip-content__border-color                       : $color-gray60 !default;
$checkout-tooltip-content__font-size                          : $font-size__base !default;
$checkout-tooltip-content__width                              : 270px !default;
$checkout-tooltip-content__active__border-color               : darken($checkout-tooltip-content__border-color, 20%) !default;
$checkout-tooltip-icon-arrow__left                            : -( $checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width) !default;

$checkout-billing-address-details__line-height                : 27px !default;

// Checkout
$checkout-step-title__padding                                 : $indent__s !default;
$checkout-step-title__border                                  : $border-width__base solid $border-color__base !default;
$checkout-step-title__font-size                               : 26px !default;
$checkout-step-title__font-weight                             : $font-weight__light !default;
$checkout-sidebar__columns                                    : 8 !default;
$checkout-shipping-address__max-width                         : 500px !default;

//
//  Sidebar
//  ---------------------------------------------

// Used in cart sidebar, Checkout sidebar, Tier Prices, My account navigation, Rating block background
$sidebar__background-color                                    : $color-white-smoke !default;

//
//  Variables missing in extend.scss
//  ---------------------------------------------

$addto-color                                                  : $text__color__muted !default;
$addto-hover-color                                            : darken($addto-color, 5%) !default;
$addto-grid-color                                             : $icon-font__color-faded;
$addto-grid-hover-color                                       : darken($addto-grid-color, 5%);

//
//  Product
//  ---------------------------------------------

$product-name-link__color                                     : $theme-color-blue1 !default;
$product-name-link__color__active                             : $theme-color-blue1 !default;
$product-name-link__color__hover                              : $theme-color-blue1 !default;
$product-name-link__color__visited                            : $theme-color-blue1 !default;

$product-name-link__text-decoration                           : none !default;
$product-name-link__text-decoration__active                   : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover                    : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited                  : $link__hover__text-decoration !default;

//
//  Button
//  ---------------------------------------------

$button__shadow                                               : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

//
//  Minicart
//  ---------------------------------------------

$minicart-icons-color                                         : $header-icons-color !default;
$minicart-icons-color-hover                                   : $header-icons-color-hover !default;
$minicart-icons-color-active                                  : $header-icons-color-active !default;

//
//  Z-index
//  ---------------------------------------------

$z-index__interactive-banner            : 2 !default;
$z-index__interactive-banner-active     : 3 !default;
$z-index__product-labels                : 5 !default;
$z-index__megamenu                      : 9 !default;
$z-index__header                        : 11 !default;
$z-index__overlay                       : 14 !default;
$z-index__off-canvas-menu               : 99 !default;
$z-index__dropdown-menu                 : 101 !default;
$z-index__sticky-header                 : 500 !default;
$z-index__modals                        : 905 !default;

//
//  Header and navigation
//  ---------------------------------------------

//  Top bar
$top-bar__display                         : none; /* none | block */

//  Middle bar
$middle-bar__background-color             : $brand__primary__color;

$middle-bar__action__color                : $brand__primary__color;
$middle-bar__action__color-hover          : $brand__primary__color;
$middle-bar__action__color-active         : $brand__primary__color;

$middle-bar__action__bg-color-hover       : lighten($brand__primary__color, 5%);
$middle-bar__action__border               : 1px solid transparent;
$middle-bar__action__border-color-active  : $border-color__base;

$middle-bar__action__dropdown-border      : 1px solid $middle-bar__action__border-color-active;

$middle-bar__search__border               : false;

//  Navigation
$header__navigation__background-color     : $brand__primary__color !default;
$header__navigation__top-link-color       : $color-white !default;
$header__navigation__border-bottom        : 1px solid $border-color__base !default;
