.block {
    @extend .abs-margin-for-blocks-and-widgets;

    .title {
        @extend .abs-block-title;
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

.sidebar {
    .block {
        @extend .abs-margin-for-blocks-and-widgets-sidebar;

        .title {
            @extend .abs-block-title-sidebar;
        }
    }
}

.modals-wrapper {
    .block {
        margin: 0;

        .title {
            margin-bottom: 20px;
        }
    }
}
