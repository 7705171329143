.dropdown-img {
    max-width: 20px;
    padding-right: 7px;
}
.page-header {
    .switcher.header {
        display: none;
    }
}

.product-usps-section {
    margin-top: 38px;
    color: $theme-color-dark-gray6;
    font-size: 14px;

    ul {
        padding-left: 0;
        list-style: none;

        li {
            @include lib-icon-font(
                $_icon-font-content: $icon-checkmark,
                $_icon-font-size: 12px,
                $_icon-font-margin: 2px 15px 0 0,
                $_icon-font-vertical-align: top,
                $_icon-font-color: $theme-color-green3
            );
        }
    }
}

@include min-screen($screen__m) {
    .page-header {
        .switcher.header {
            display: block;
            color: $color-white;
            margin-left: 10px;

            .options {
                .switcher-trigger {
                    &:after {
                        font-size: 12px;
                    }
                }
                .switcher-trigger.active {
                    &:after {
                        font-size: 12px;
                    }
                }

                .switcher-dropdown {
                    min-width: 70px;
                    right: 0;
                    border-radius: 3px;
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .page-header {
        .nav-sections-item-content {
            .switcher {
                .options {
                    float: right;

                    .switcher-trigger {
                        &:after {
                            font-size: 12px;
                        }
                    }

                    .switcher-trigger.active {
                        &:after {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
