$toolbar-mode-icon-font-size: 16px;
$toolbar-background: transparent;
$toolbar-element-background: #fff;
$toolbar-element__color: $color-gray37;
$pager-action__background: $toolbar-element-background;
$pager-action__hover__background: darken($toolbar-element-background, 7%);
$pager__font-weight: $font-weight__bold;
$button__shadow-active: inset 0 1px 0 0 rgba($border-color__base, 0.8), inset 0 -1px 0 0 rgba($border-color__base, 0.3);

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    
    &__filters {
        display: flex;
    }
    
    &-top &__bottom {
        display: none;
    }
    
    &-bottom &__top {
        display: none;
    }
    
    &-bottom {
        @extend .abs-margin-for-blocks-and-widgets;
        display: flex;
        justify-content: center;
        padding-top: 20px;
        border-top: 1px solid $border-color__base;
        
        .trigger-filters {
            display: none;
        }
    }
    
    select {
        @include lib-css(background-color, $toolbar-element-background);
        @include lib-css(height, $form-element-input__height);
        border-radius: 3px;
    }
    
    &-amount {
        flex: 1;
        left: 0;
        line-height: 1;
        margin: 0;
        padding: 7px 0;
        text-align: left;
        top: 0;
        vertical-align: middle;
    }
    
    .page-with-filter & {
        &-amount {
            position: static;
        }
    }
    
    &-products {
        @include lib-css(background-color, $toolbar-background);
    }
}

.sorter {
    &-options {
        width: auto;
    }
    
    &-action {
        @include lib-icon-font(
                $icon-arrow-up,
            $_icon-font-size: 16px,
            $_icon-font-color: $header-icons-color,
            $_icon-font-color-hover: $header-icons-color-hover
        );
        @include lib-icon-text-hide();
        
        &.sort-desc:before {
            content: $icon-arrow-down;
        }
    }
}

.limiter {
    &-options {
        margin: 0 10px;
        width: auto;
    }
    
    &-label {
        font-weight: 400;
    }
    
    .control {
        display: inline-block;
    }
}

.modes-mode {
    @include lib-icon-font(
        $_icon-font-content: $icon-grid,
        $_icon-font-size: $toolbar-mode-icon-font-size,
        $_icon-font-text-hide: true,
        $_icon-font-color: $text__color__muted,
        $_icon-font-color-hover: $text__color__muted
    );
    line-height: 1;
    text-align: center;
    
    &:not(.mode-grid) {
        margin: 0 10px;
    }
    
    &.active {
        &::before {
            color: $brand__primary__color;
        }
    }
}

.modes-label {
    @extend .abs-visually-hidden;
}

.mode-list {
    &::before {
        content: $icon-list;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .toolbar-products {
        margin-bottom: 0;
    }
    
    .toolbar-bottom {
        margin-left: -$layout-indent__width;
        margin-right: -$layout-indent__width;
        margin-bottom: 30px;
        
        .toolbar {
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
        }
    }
    
    .toolbar {
        &__filters {
            align-items: center;
        }
        
        .limiter-options {
            margin: 10px 10px 10px 0;
        }
        
        .sorter-options {
            width: 100%;
        }
    }
}

@include max-screen($screen__s) {
    .toolbar {
        &__top {
            justify-content: space-between;
        }
        
        &-amount {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            position: relative;
            z-index: 1;
        }
    }
    
    .modes {
        display: inline-block;
    }
    
    .sorter {
        .page-products & {
            position: static;
        }
    }
}

@include min-screen($screen__l) {
    .toolbar-amount {
        display: block;
        position: static;
    }
}
