$footer__background-color: false !default;

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer {
    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }
}

.footer-bottom {
    margin-bottom: 40px;
    text-align: center;
}

.footer-columns {
    margin-bottom: 40px;
}

.footer-copyright {
    font-size: 12px;
}

.footer-payment-providers {
    margin-bottom: 10px;
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

}

.footer-socials {
    text-decoration: none;
}

.top-footer {
    margin-bottom: 40px;

    &__inner {
        border-bottom: 1px solid $border-color__base;
        padding: 25px 0;
    }


    &__right {
        ul {
            @include lib-list-reset-styles();
            display: flex;
        }

        li {
            @include lib-list-reset-styles();
            font-size: 35px;
            font-weight: $font-weight__extrabold;
            color: $theme-color-blue1;

            span {
                color: $brand__secondary__color
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer-columns {
        @include make-row();
        margin-bottom: 60px;
        flex-wrap: inherit;

        .footer-menu-container {
            width: 100%;
        }

        .footer-menu {
            display: flex;
            justify-content: space-between;

            p {
                margin: 0;
            }

            ul {
                margin: 0;
                padding: 0;
            }

            &__item {
                width: 25%;
            }

            &__top-link,
            &__sub-menu-link {
                @include lib-link-all(
                    $_link-color: $text__color
                );
            }

            &__sub-menu-item {
                font-size: 14px;
                color: $brand__primary__color;

                a {
                    @include lib-link-all(
                        $_link-color: $brand__primary__color
                    );
                }
            }

            &__sub-menu-topall {
                display: none;
            }

            &__top-link {
                @include lib-heading(h4);
                @include lib-link-all(
                    $_link-color: $heading__color__base
                );
                display: inline-block;
                margin-bottom: 15px;
            }

            &__sub-menu-item {
                margin-bottom: 8px;
                line-height: 1.2;
            }
        }
    }

    .footer-bottom {
        margin-bottom: 60px;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        margin-bottom: 60px;

        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}

//
//  Mobile
//  _____________________________________________


@include max-screen($screen__m) {
    .footer.content .footer-menu {
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;

        &__item {
            position: relative;
            margin: 0;
            padding: 15px 35px 15px $layout-indent__width;
            border-bottom: 1px solid $border-color__base;

            &:first-child {
                border-top: 1px solid $border-color__base;
            }

            ul {
                padding-left: 0;
            }
        }

        &__sub-menu-item {
            margin-bottom: 0;
        }

        &__top-link {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }

        &__top-linkall {
            display: none;
        }

        &__sub-menu-link {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }
    }

    .footer-columns {
        .block.newsletter {
            display: none;
        }

        .social-media {
            margin-top: 20px;
        }
    }

    .top-footer {
        display: none;
    }

    .company-info-wrapper {
        padding-top: 10px;
    }
}
