@mixin fotorama-shadow-gradient($x, $y) {
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at $x $y, rgba(0, 0, 0, 0.4), transparent);
}

@mixin fotorama-inline-block($va: middle) {
    display: inline;
    display: inline-block;
    vertical-align: $va;
    zoom: 1;
}

@mixin fotorama-button-background() {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color $fotorama-duration-time ease-in-out;
    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
    &:active {
        background-color: rgba(213, 213, 213, 0.5);
    }
}

@mixin fotorama-abs-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}
