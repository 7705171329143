.blog-list-text {
    &__item {
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color__base;

        &:not(:first-child) {
            padding-top: 10px;
        }
    }

    &__title {
        @include lib-link(
            $_link-color                   : $text__color,
            $_link-color-visited           : $text__color,
            $_link-color-hover             : $text__color,
            $_link-color-active            : $text__color
        );
        @include text-truncate($line-height: 1.2);
    }
}

.blog-search {
    &__inner {
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-display: block,
            $_icon-font-color: $theme-icon-color-light,
            $_icon-font-size: 16px
        );
        position: relative;

        input[type=text] {
            padding-left: 34px;
        }

        &::before {
            position: absolute;
            top: 4px;
            left: 10px;
        }
    }

    &__results {
        @extend .abs-search-results;

        &:empty {
            display: none;
        }
    }

    &__anchor {
        @include lib-link(
            $_link-color                   : $text__color,
            $_link-color-visited           : $text__color,
            $_link-color-hover             : $text__color,
            $_link-text-decoration-hover   : none,
            $_link-color-active            : $text__color,
            $_link-text-decoration-active  : none
        );
        display: inline-block;
        padding: 10px 15px;
    }
}
