@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/inter/light/Inter-Light',
    $font-weight: 200,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/inter/regular/Inter-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/inter/medium/Inter-Medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/inter/semibold/Inter-Semibold',
    $font-weight: 600,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.std {
    @include lib-typography-cms-lists();
}
