.daily-deal-widget {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__sub-title {
        color: $brand__primary__color;
    }

    &__image {
        display: block;
        margin-right: 15px;
    }

    &__image-anchor {
        display: block;
    }

    &__details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__anchor {
        white-space: nowrap;
    }

    &__product-name {
        @include text-truncate(15px, $fixed-height: false);
        margin-bottom: 11px;
    }

    &__price {
        @extend .abs-margin-for-product-elements;
        line-height: 1;
    }

    &__special-price {
        @extend .abs-price-lg;
    }

    &__old-price {
        @extend .abs-price-sm;
        text-decoration: line-through;
    }
}

.sidebar .daily-deal-widget {
    &__content {
        display: block;
    }
}

@include min-screen($screen__s) {
    .daily-deal-widget {
        padding: 15px;
        border: 1px solid $border-color__base;

        &__content {
            margin: 15px auto;
        }

        &__title {
            text-align: center;
        }

        .timer {
            justify-content: center;
        }
    }
}

@include min-screen($screen__m) {
    .daily-deal-widget {
        margin-bottom: 20px;

        &__content {
            margin: 0 auto;
        }
    }
}

@include min-screen($screen__l) {
    .daily-deal-widget {
        &__content {
            max-width: 70%;
            margin: 15px auto;
        }

        &__product-name {
            @include text-truncate(17px, $fixed-height: false);
        }
    }

    .sidebar .daily-deal-widget {
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;

        &__image {
            margin: 0 auto 15px;
        }

        &__content {
            max-width: none;
            margin: 10px 0 0;
        }
    }
}

@include max-screen($screen__l) {
    .daily-deal-widget {
        justify-content: space-between;

        &__image {
            max-width: 100px;
        }

        &__sub-title {
            display: none;
        }
    }
}

@include max-screen($screen__s) {
    .daily-deal-widget {
        padding: 30px 16px;
        margin-left: -16px;
        margin-right: -16px;
        border-top: 1px solid $border-color__base;
        border-bottom: 1px solid $border-color__base;

        &__image-anchor {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        &__title {
            order: 1;
        }

        .timer {
            order: 2;
        }

        &__content {
            order: 3;
        }
    }
}
