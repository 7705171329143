@keyframes reveal {
    from { transform: scale(0.001); }
    to { transform: scale(1); }
}

@keyframes slide {
    to { transform: translateX(1.5em) }
}

.mst-scroll__loader {
    font-size: 20px;
    position: relative;
    width: 4em;
    height: 1em;
    margin: 10px auto;

    .loader-dot {
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 0.5em;
        background: #555;
        position: absolute;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;

        &:nth-child(1),
        &:nth-child(2) {
            left: 0;
        }

        &:nth-child(3) {
            left: 1.5em
        }
        &:nth-child(4) {
            left: 3em;
        }

        &:nth-child(1) {
            animation-name: reveal;
        }

        &:nth-child(2),
        &:nth-child(3) {
            animation-name: slide;
        }

        &:nth-child(4) {
            animation-name: reveal;
            animation-direction: reverse;
        }
    }
}
