.breadcrumbs {
    @include lib-breadcrumbs();
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .breadcrumbs {
        display: none;
    }
}
