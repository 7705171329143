.related-post {
    display: block;

    &__image {
        width: 100%;
        padding-bottom: 25%;
        background-size: cover;
    }

    &__details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 25px 15px;
        background-color: $brand__primary__color;
    }

    &__title {
        @include lib-heading(h3);
        color: $color-white;
    }

    &__sub-title {
        color: $brand__primary__color__light;
        font-weight: $font-weight__medium;
    }

    &:hover,
    &:visited {
        text-decoration: none;
    }

    &:hover &__details {
        background-color: $brand__primary__color__dark;
    }

    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

@include min-screen($screen__s) {
    .related-post {
        display: flex;
        flex-wrap: wrap;

        &__image {
            padding-bottom: 26%;
            width: 30%;
        }
    }
}

@include min-screen($screen__l) {
    .related-post {
        &__image {
            padding-bottom: 38%;
        }

        &__details {
            padding: 30px 20px;
        }
    }
}