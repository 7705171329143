.wishlist-wrapper {
    .label {
        @extend .abs-visually-hidden;
    }

    .header-action {
        @include lib-icon-font(
            $_icon-font-content: $icon-wishlist-empty,
            $_icon-font-display: false,
            $_icon-font-size: 22px
        );
    }
}
