.header-top-bar {
    @include lib-css(display, $top-bar__display);
    padding: 5px 0;
    border-bottom: 1px solid $border-color__base;
    font-size: 14px;
}

.header-top-bar-inner {
    display: flex;
    justify-content: space-between;
}

.header-top-bar-usps-container {
    @extend .abs-usps-horizontal;
}

@include max-screen($screen__l) {
    .header-phonenumber {
        display: none;
    }
}

@include max-screen($screen__m) {
    .header-top-bar {
        display: none;
    }
}
