.category-grid-widget {
    &__image-anchor {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    &__anchor {
        @include lib-link(
            $_link-color: $text__color,
            $_link-color-visited: $text__color,
            $_link-color-hover: $text__color,
            $_link-color-active: $text__color
        );
        font-weight: $font-weight__medium;
        margin-top: 15px;
    }

    &__item {
        padding-right: 15px;
    }
}

.cms-alle-categorieen {
    .category-grid-widget {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        &__item {
            width: 20%;
            padding-right: 25px;
            padding-bottom: 25px;
            box-sizing: border-box;
        }

        &__details {
            text-align: center;
        }
    }

    .category-wrapper {
        align-items: flex-end;
        flex-wrap: wrap;
    }
}

.category-wrapper {
    display: flex;
}

.home-product-groups {
    text-align: center;

    .category-grid-widget {
        &__image-anchor {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 210px;
            background: $product-background-color;
            position: relative;

            img {
                max-height: 170px
            }

            &::after {
                content: '';
                background: $color-black;
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: 0.03;
                left: 0;
                top: 0;
            }
        }
    }
}

.home-brands {
    text-align: center;

    .category-grid-widget {
        &__image-anchor {
            border: 1px solid $theme-color-blue1;
            padding: 40px 30px;
            min-height: 50px;
            max-height: 50px;
        }

        &__details {
            text-transform: uppercase;
        }
    }
}

@include min-screen($screen__l) {
    .home-brands {
        .category-grid-widget__image-anchor {
            max-width: 130px;
        }
    }
}

@include max-screen($screen__l) {
    .category-wrapper {
        flex-wrap: wrap;

        .category-grid-widget {
            &__item {
                width: 50%;
                max-width: 50%;
                box-sizing: border-box;
            }

            &__image-anchor {
                margin-bottom: 15px;
            }

            &__details {
                margin-bottom: 15px;
            }
        }
    }

    .home-brands {
        .category-grid-widget__image-anchor {
            min-height: 80px;
            height: 80px;
        }
    }
}
