@import 'swiper';
@import 'navigation';

.swiper-slide {
    box-sizing: border-box;
}

.swiper-button-lock {
    display: none;
}

@include max-screen($screen__m) {
    .swiper-products {
        &__item {
            width: 45%;
        }
    }

    .swiper-container {
        margin: 0 -#{$layout-indent__width};
        padding: 0 $layout-indent__width;
    }

    .swiper-nav {
        display: none;
    }
}

// This fixes swiper not working when in a column. However, if items are wide enough it is not needed.
// Only god knows why.
//.swiper-slide {
//    display: flex;
//    justify-content: center;
//}
