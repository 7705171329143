//
//  Remove whitespace for images in WYSIWYG
//  _____________________________________________

.wysiwyg-img {
    line-height: 1;
    font-size: 0;

    p {
        margin: 0;
    }
}

//
//  Flex spacer
//  _____________________________________________

.flex-spacer {
    width: 1px;
}

//
//  Margins
//  _____________________________________________

.mb-s {
    margin-bottom: $indent__s;
}

.mb-m {
    margin-bottom: $indent__m;
}

.mb-l {
    margin-bottom: $indent__l;
}

.mb-xl {
    margin-bottom: $indent__xl;
}

.mb-primary {
    @extend .abs-margin-for-blocks-and-widgets;
}

//
//  Call to Action link
//  _____________________________________________

.cta-link {
    @extend .abs-cta-link;
}

//
//  Hide elements
//  _____________________________________________

.hidden {
    @include lib-visually-hidden();
}
