.mst-search__highlight {
    font-weight: $font-weight__regular;
}

.suggested {
    a {
        color: $text__color;
        font-weight: $font-weight__medium;
    }
}
