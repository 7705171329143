.cart-popup {
    &__inner {
        margin-bottom: 20px;
    }

    &__image {
        width: 100px;
    }

    &__product-title {
        @extend .product-item-name;
        font-size: 17px;
    }

    &__price {
        @extend .abs-price-md;
    }
}

@include min-screen($screen__m) {
    .cart-popup {
        &__inner {
            display: flex;
        }

        &__image {
            margin-right: 20px;
        }
    }
}
