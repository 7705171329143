.mst-search__index {
    ul:not(.blog-post-info) {
        list-style: none;
        margin-bottom: 10px;
        padding-left: 0;

        li:not(.blog-post-info-item) {
            @include lib-clearfix();
            margin: 2rem 0;

            &:last-child:not(.blog-post-info-item){
                margin-bottom: 0;
            }

            .mst-search__index-title {
                font-size: 1.4rem;
                font-weight: 600;

                > a {
                    color: #333;
                }

                > span {
                    color: #aaa;
                }
            }

            .mst-search__index-content {
                color: #666;
                margin: .5rem 0 0 0;
            }

            .mst-search__index-image {
                margin-right: 2rem;
                float: left;

                > img {
                    max-width: 5rem;
                    max-height: 5rem;
                }
            }
        }
    }
}
