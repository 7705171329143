.btn {
    @include lib-button();
    @include lib-link-as-button();
    @include lib-css(border-radius, $button__border-radius);

    &--primary {
        @include lib-button-primary();
    }

    &--secondary {
        @include lib-button-secondary();
    }

    &--sm {
        @include lib-button-s();
    }

    &--md {
        @include lib-button-m();
    }

    &--lg {
        @include lib-button-l();
    }

    &--full {
        @include lib-button-responsive();
    }

    &--as-link {
        @include lib-button-as-link();
    }

    &--rounded {
        @include lib-css(border-radius, $button__border-radius-rounded);
    }

    &--arrow {
        @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-margin: 0 5px 0 -3px,
            $_icon-font-size: 12px
        );
    }
}

@include max-screen($screen__s) {
    .btn {
        &--full-s {
            @include lib-button-responsive();
        }
    }
}
