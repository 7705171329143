.navigation-menu {
    &__list,
    &__sub-menu-row,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }
    
    &__sub-menu-top-link {
        @include lib-link-all(
            $_link-color: $text__color
        );
        font-weight: $font-weight__medium;
    }
    
    &__sub-menu {
        .all {
            display: none;
        }
    }
}

@include min-screen($screen__m) {
    .navigation-menu-container {
        @include lib-css(background-color, $header__navigation__background-color);
    }
    
    .navigation-menu {
        &-container {
            position: relative;
        }
        
        &__list {
            display: flex;
            overflow-x: auto;
            white-space: nowrap;
        }
        
        &__item {
            margin: 0;
            
            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
        
        &__top-link {
            @include lib-link-all(
                $_link-color: $header__navigation__top-link-color
            );
            display: inline-block;
            padding: 25px 0;
            font-weight: $font-weight__medium;
            font-size: 14px;
        }
        
        &__sub-menu-container {
            position: absolute;
            z-index: $z-index__megamenu;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            padding: 30px 0 10px;
            background-color: $color-white;
            border-top: 1px solid $border-color__base;
            border-bottom: 1px solid $border-color__base;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            
            .navigation-menu__top-link {
                display: none;
            }
        }
        
        &__sub-menu-row {
            @include make-row();
        }
        
        &__sub-menu-column {
            @include make-col-ready();
            @include make-col(8);
            margin-bottom: 20px;
        }
        
        &__sub-menu-list {
            margin-top: 5px;
        }
        
        &__top-linkall {
            display: none;
        }
        
        &__sub-menu-item {
            display: block;
            line-height: 1.2;
            margin: 0;
            
            a {
                @include lib-link-all(
                    $_link-color: $theme-text-color
                );
            }
            
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

@include min-screen($screen__l) {
    .navigation-menu {
        &__sub-menu-column {
            @include make-col(6);
        }
    }
}

@include max-screen($screen__m) {
    .nav-sections {
        a {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }
    }
    
    .navigation-menu,
    .nav-sections .footer-menu {
        a {
            display: block;
            padding: $navigation-level0-item__padding;
            border-bottom: 1px solid $color-gray82;
            
            &:hover {
                text-decoration: none;
            }
        }
        
        &__item.parent {
            @include lib-icon-font(
                $_icon-font-content  : $icon-down,
                $_icon-font-size     : 42px,
                $_icon-font-position : after,
                $_icon-font-display  : block
            );
            position: relative;
            
            &::after {
                @include lib-font-size(14);
                position: absolute;
                right: 17px;
                top: 12px;
                pointer-events: none;
            }
        }
        
        &__item {
            margin: 0;
            
            &--active.parent {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }
        
        &__top-linkall {
            margin-bottom: 10px;
        }
    }
}

//
//  Debugging
//  _____________________________________________

//.navigation-menu__item:first-child > div {
//    display: block !important;
//}
