.banner-grid {
    @include make-row();
    @extend .abs-margin-for-blocks-and-widgets;
    
    &__item {
        @include make-col-ready();
        @include make-col(8);
        @extend .wysiwyg-img;
    }
}

.grid-banner {
    display: flex;
    
    .block-static-block {
        display: flex;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 8px;
        padding-left: 8px;
    }
}

@include max-screen($screen__m) {
    .banner-grid {
        display: none;
    }
    
    .grid-banner {
        flex-direction: column;
        
        .block-static-block {
            padding: 0;
        }
    }
    
    .grid-row {
        justify-content: center;
    }
}
