.opc-wrapper {
    .form-discount {
        max-width: 500px;
        .field {
            .label {
                @extend .abs-visually-hidden;
            }
        }
    }
}
