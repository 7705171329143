.pages {
    @include lib-pager();

    .action {
        &.previous {
            margin-right: 10px;
        }

        &.next {
            margin-left: 10px;
        }
    }
}

@include max-screen($screen__s) {
    .pages {
        .items {
            justify-content: center;
        }

        .pages-item-previous,
        .pages-item-next {
            width: 100%;
            justify-content: center;
        }

        .pages-item-previous {
            margin-bottom: 10px;
        }

        .pages-item-next {
            margin-top: 10px;
        }
    }
}
