.footer-columns {
    .social-media {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        
        &__title {
            @extend .footer-menu__top-link;
            @include lib-link(
                $_link-color: $heading__color__base,
                $_link-color-visited: $heading__color__base,
                $_link-color-active: $heading__color__base,
                $_link-color-hover: $heading__color__base,
                $_link-text-decoration-hover: none,
                $_link-text-decoration-active: none
            );
        }
        
        &__anchor {
            @include lib-link(
                $_link-color: $theme-color-dark-gray1,
                $_link-color-visited: $theme-color-dark-gray1,
                $_link-color-hover: $theme-color-dark-gray1,
                $_link-text-decoration-hover: underline,
                $_link-color-active: $theme-color-dark-gray1,
                $_link-text-decoration-active: none
            );
            text-transform: capitalize;
        }
    }
}

@include max-screen($screen__m) {
    .footer.content .footer-columns {
        .social-media {
            &__title {
                @include lib-link(
                    $_link-color: $theme-color-dark-gray1,
                    $_link-color-visited: $theme-color-dark-gray1,
                    $_link-color-active: $theme-color-dark-gray1,
                    $_link-color-hover: $theme-color-dark-gray1,
                    $_link-text-decoration-hover: none,
                    $_link-text-decoration-active: none
                );
            }
            
            &__label {
                @include lib-link-all(
                    $_link-color: $color-blue1
                );
            }
        }
    }
}
