.brand-brand-view .columns {
  position: relative;
}
.m-brand-logo {
  float: left;
  margin-right: 10px;
  width: 200px;
}
.m-brand-additional-info {
  margin: 0 0 35px 0;
  display:inline-block;
}

.brand-brand-view .m-brand-banner img {
  width: 100%;
}
