.mobile-navigation {
    @extend .abs-margin-for-blocks-and-widgets;

    &__list {
        @include lib-list-reset-styles();
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;
        border-top: 1px solid $border-color__base;
    }

    &__item {
        @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-display: block
        );
        position: relative;
        margin: 0;
        padding: 15px 35px 15px $layout-indent__width;
        border-bottom: 1px solid $border-color__base;

        & > * {
            margin: 0;
        }

        a,
        span.hide {
            @include lib-text-hide();
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        &::before {
            position: absolute;
            top: 50%;
            right: $layout-indent__width;
            transform: translateY(-50%);
        }
    }

    &__anchor {
        display: block;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    h2,
    h3,
    h4 {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: $font-weight__regular;
    }

    p {
        font-size: 12px;
        color: $text__color;
    }
}

@include min-screen($screen__m) {
    .mobile-navigation {
        display: none;
    }
}
