//
//  Variables
//  _____________________________________________

$active-nav-indent: 42px !default;

.panel.header {
    .topbar__list,
    .links,
    .switcher {
        display: none;
    }
}

.nav-toggle {
    @include lib-icon-font(
        $_icon-font-content: $icon-menu,
        $_icon-font-size: 16px,
        $_icon-font-color: $middle-bar__action__color,
        $_icon-font-color-hover: $middle-bar__action__color
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    z-index: $z-index__overlay;
}

.nav-toggle-mobile {
    @include lib-icon-font(
        $_icon-font-content: $icon-remove,
        $_icon-font-size: 16px,
        $_icon-font-color: $theme-icon-color-regular
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    top: 10px;
    right: 15px;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }
    
    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: -80%;
        left: calc(-1 * (100vw - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        padding-top: 30px;
        width: 80%;
        width: calc(100vw - #{$active-nav-indent});
        
        .footer-menu {
            ul {
                padding-left: 0;
            }
        }
        
        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;
            
            .label {
                display: block;
                margin-bottom: $indent__xs;
            }
            
            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }
        
        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );
                
                &::after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }
            
            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }
        
        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }
        
        .section-item-content:not(#store\.menu) > ul {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;
            
            li {
                margin: 0;
                
                &.greet.welcome {
                    display: none;
                    border-top: 1px solid $color-gray82;
                    border-bottom: 1px solid $color-gray82;
                    font-weight: $font-weight__regular;
                    padding: $navigation-level0-item__padding;
                }
                
                &.customer-welcome {
                    .customer-name {
                        display: none;
                    }
                }
                
                > a {
                    border-top: 1px solid $color-gray82;
                }
            }
            
            a,
            a:hover {
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: $font-weight__regular;
                padding: $navigation-level0-item__padding;
            }
            
            .header.links {
                border: 0;
                list-style: none;
                padding-left: 0;
            }
        }
    }
    
    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;
        
        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }
        
        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }
    
    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100vw - #{$active-nav-indent});
        }
        
        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            
            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            background: $color-white;
            z-index: $z-index__off-canvas-menu;
        }
        
        .all {
            display: none;
        }
        
        .nav-toggle {
            &::after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }
    
    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }
    
    .nav-sections-item-title {
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 1.6rem;
        
        .nav-sections-item-switch {
            font-weight: $font-weight__medium;
            
            &:hover {
                text-decoration: none;
            }
        }
    }
    
    .nav-sections-item-content {
        box-sizing: border-box;
        padding: 0 0 40px 15px;
    }
    
    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle-mobile {
        display: none;
    }
    
    .nav-sections {
        @include lib-css(background, $navigation__background);
    }
    
    .header.panel > .header.links > .customer-welcome + .authorization-link {
        display: none;
    }
    
    .nav-toggle {
        display: none;
    }
    
    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        margin-bottom: $indent__m;
        @include lib-css(background, $navigation-desktop__background);
    }
    
    .nav-sections-item-title {
        display: none;
    }
    
    .nav-sections-item-content {
        display: block !important;
    }
    
    .nav-sections-item-content > * {
        display: none;
    }
    
    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }
    
    @include lib-main-navigation-desktop();
    
    .panel.header {
        .topbar__list,
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
