a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary();
}

.action.secondary {
    @include lib-button-secondary();
}
