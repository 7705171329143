//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                              : $font-size__base !default;
$checkout-progress-bar__font-weight                            : $font-weight__regular !default;
$checkout-progress-bar__margin                                 : $indent__base !default;

$checkout-progress-bar-item__background-color                  : $color-gray-middle1 !default;
$checkout-progress-bar-item__border-radius                     : 6px !default;
$checkout-progress-bar-item__color                             : $primary__color !default;
$checkout-progress-bar-item__margin                            : $indent__s !default;
$checkout-progress-bar-item__width                             : 185px !default;
$checkout-progress-bar-item__active__background-color          : $brand__primary__color !default;
$checkout-progress-bar-item__complete__color                   : $link__color !default;

$checkout-progress-bar-item-element__width                     : 38px !default;
$checkout-progress-bar-item-element__height                    : $checkout-progress-bar-item-element__width !default;

$checkout-progress-bar-item-element-inner__background-color    : $page__background-color !default;
$checkout-progress-bar-item-element-inner__color               : $checkout-progress-bar-item__color !default;
$checkout-progress-bar-item-element-outer-radius__width        : 6px !default;
$checkout-progress-bar-item-element-inner__width               : $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2) !default;
$checkout-progress-bar-item-element-inner__height              : $checkout-progress-bar-item-element-inner__width !default;
$checkout-progress-bar-item-element-inner__active__content     : $icon-checkmark !default;
$checkout-progress-bar-item-element-inner__active__font-size   : false !default;
$checkout-progress-bar-item-element-inner__active__line-height : false !default;

//
//  Checkout Progress Bar
//  ---------------------------------------------

.opc-progress-bar {
    @extend .abs-reset-list;
    @include lib-css(margin, 0 0 $checkout-progress-bar__margin);
    display: flex;
    counter-reset: i;
    font-size: 0;
    text-align: center;
}

.opc-progress-bar-item {
    @include lib-css(margin, 0 0 $checkout-progress-bar-item__margin);
    flex: 1;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;

    &:not(:first-child)::before { // Horizontal line
        @include lib-css(background, $checkout-progress-bar-item__background-color);
        @include lib-css(top, $checkout-progress-bar-item-element__width/2);
        content: '';
        height: 7px;
        transform: translateY(-50%);
        right: calc(50% + #{$checkout-progress-bar-item-element__width} / 2);
        position: absolute;
        width: calc(100% - #{$checkout-progress-bar-item-element__width});
    }

    > span {
        display: inline-block;
        padding-top: 45px;
        width: 100%;
        word-wrap: break-word;

        @include lib-typography(
            $_color       : $checkout-progress-bar-item__background-color,
            $_font-family : false,
            $_font-size   : $checkout-progress-bar__font-size,
            $_font-style  : false,
            $_font-weight : $checkout-progress-bar__font-weight,
            $_line-height : false
        );

        &::before, // Item element
        &::after {
            @include lib-css(background, $checkout-progress-bar-item__background-color);
            @include lib-css(height, $checkout-progress-bar-item-element__height);
            @include lib-css(margin-left, -($checkout-progress-bar-item-element__width/2));
            @include lib-css(width, $checkout-progress-bar-item-element__width);
            border-radius: 50%;
            content: '';
            left: 50%;
            position: absolute;
            top: 0;
        }

        &::after { // Item element inner
            @include lib-css(background, $checkout-progress-bar-item-element-inner__background-color);
            @include lib-css(height, $checkout-progress-bar-item-element-inner__height);
            @include lib-css(margin-left, -($checkout-progress-bar-item-element-inner__width/2));
            @include lib-css(top, $checkout-progress-bar-item-element-outer-radius__width);
            @include lib-css(width, $checkout-progress-bar-item-element-inner__width);
            content: counter(i);
            counter-increment: i;
            @include lib-typography(
                $_color       : $checkout-progress-bar-item-element-inner__color,
                $_font-family : false,
                $_font-size   : $checkout-progress-bar__font-size,
                $_font-style  : false,
                $_font-weight : $font-weight__semibold,
                $_line-height : 28px
            );
        }
    }

    &._active {
        &::before {
            background: $checkout-progress-bar-item__active__background-color;
        }

        > span {
            @include lib-css(color, $checkout-progress-bar-item__color);

            &::before {
                @include lib-css(background, $checkout-progress-bar-item__active__background-color);
            }
        }
    }

    &._complete {
        cursor: pointer;

        &::before {
            background: $checkout-progress-bar-item__active__background-color;
        }

        > span {
            @include lib-css(color, $checkout-progress-bar-item__color);

            &::before {
                @include lib-css(background, $checkout-progress-bar-item__active__background-color);
            }

            &::after {
                @include lib-css(content, $checkout-progress-bar-item-element-inner__active__content);
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(line-height, $checkout-progress-bar-item-element-inner__active__line-height);
                font-size: 15px;
                line-height: 28px;
                background-color: $checkout-progress-bar-item__active__background-color;
                color: $color-white;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-progress-bar {
        display: block;
    }

    .opc-progress-bar-item {
        @include lib-css(width, $checkout-progress-bar-item__width);
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .opc-progress-bar-item {
        > span {
            font-size: 0;
        }
    }
}
