.top-search__container {
    position: relative;
}

.header-middle-bar__search {
    .block-search {
        padding-left: 0;
    }
}

.autocomplete-wrapper {
    @extend .abs-search-styles;
    margin-top: 10px;
    min-height: 3.5rem;
    box-sizing: border-box;
    text-align: left;
    position: absolute;
    width: 100%;
    display: none;

    &.active {
        display: block;
    }

    .autocomplete {
        .item-count {
            padding: 8px 10px;
            text-align: right;
            font-size: 13px;
            line-height: 13px;
            color: #999;
        }

        ul.item-wrapper {
            list-style: none;
            margin: 0;
            padding: 0;

            .autocomplete-item {
                display: flex;
                align-items: flex-start;
                margin: 0;
                padding: 5px 1rem;
                cursor: pointer;

                &:hover {
                    background-color: #f5f5f5;
                    text-decoration: none;
                }

                img {
                    width: 40px;
                    margin-right: 1rem;
                }

                .meta {
                    flex-grow: 1;
                    overflow: hidden;

                    .title {
                        display: block;
                        margin-bottom: 0;

                        span {
                            color: #6b6c6f;
                            text-decoration: none;
                        }
                    }

                    .price {
                        span {
                            color: #163961;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .categories {
            padding-left: 10px;

            .item-wrapper span {
                color: #163961;
            }
        }


        .no-results {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 10px;
            flex-direction: column;

            span, p {
                text-align: center;
                font-size: 1.2rem;
            }

            p {
                margin-bottom: 0;
            }

            .suggest {
                text-decoration: underline;
                color: #163961;
                
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.autocomplete-loader {
    position: absolute;
    top: 10px;
    right: 40px;
    width: 25px;
    display: none;

    &.active {
        display: block;
    }
}

.product-list-wrapper.loading {
    opacity: 0.5;
}

.filters {
    .selected-filters {
        h4 {
            border-bottom: 1px solid #d8dbe5;
            padding-bottom: 16px;
        }

        ol {
            list-style: none none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: $font-size__s;

                .remove-button {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-remove,
                        $_icon-font-size: 10px,
                        $_icon-font-margin: 0 10px 0 0,
                    );

                    position: absolute;
                    width: 12px;
                    height: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        cursor: pointer
                    }
                }

                .attribute-title {
                    margin-left: 10px;
                    max-width: 80%;
                }
            }
        }
    }

    .filter-options-item {
        .filter-options-content {
            display: none;
            margin-bottom: 15px;

            .price-filter {
                display: flex;
                align-items: center;

                input {
                    min-width: 0;
                    font-size: $font-size__s;
                    font-weight: 400;
                }

                .to {
                    padding: 0 5px;
                }

                button {
                    border: 0;
                    width: 50px;
                    min-width: 50px;
                    height: 32px;
                    margin-left: 10px;
                    @include lib-icon-font(
                        $_icon-font-content: $icon-next,
                        $_icon-font-size: 18px,
                        $_icon-font-margin: 0 10px 0 0,
                    );

                }
            }
        }

        &.active {
            .filter-options-content {
                display: block;
            }
        }
    }
}


.autocomplete-loader {
    position: absolute;
    top: 10px;
    right: 40px;
    width: 25px;
    display: none;

    &.active {
        display: block;
    }
}

.product-list-wrapper.loading {
    opacity: 0.5;
}

@include max-screen($screen__m) {
    .autocomplete-wrapper {
        display: none;
        width: 100%;
    }
}

@include min-screen($screen__m) {
    .autocomplete-wrapper {
        padding: 0 7px 0 8px;
    }
}
