.brand-brand-index .brand .filter .brand-filter-link {
  display: block;
  float: left;
  margin: 0 1px 10px;
  padding: 2px 5px;
  min-width: 17px;
  min-height: 20px;
  text-align: center;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #e2e2e2;
}
.brand-brand-index .brand .filter .letter-all-brand {
  margin-right: 100px;
  margin-bottom: 50px;
}
.brand-brand-index .brand-options {
  clear: left;
}
.brand-brand-index .brand-letter {
  /*float: left;*/
  top: 0;
  left: 0;
  font-size: 40px;
  line-height: 60px;
  clear: left;
}
.brand-brand-index .brand-row > ul {
  list-style: none;
  margin: 0;
  /*padding: 0;*/
}
.brand-brand-index .brand-row > ul > li {
  float: left;
  padding-top: 20px;
  padding-left: 30px;
  /*width: 190px;*/
  /*height: 40px;*/
}
.brand-brand-index .brand-letter {
  float: left;
}

.brand-brand-index .brand-row {
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
}

.brand-brand-index .brand-row ul li a {
  color: #333;
  text-decoration: none;
}

.brand-brand-index .brand-row ul li a:hover {
  text-decoration: underline;
}

.brand-brand-index .brand .filter .letter .brand-filter-link:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.brand-brand-index .brand .filter .letter .brand-filter-link:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.brand-brand-index .brand .filter .letter .brand-filter-link {
  margin: 0;
  border-radius: 0;
  border-right: none;
}

.brand-brand-index .brand .filter .all-brand a:hover {
  text-decoration: underline;
}

.brand-brand-index .brand .filter .letter a:hover {
  text-decoration: underline;
}

// hide/show elements
.brand-brand-index .brand-options .brand-row.hide {
  display: none;
}

.brand-brand-index .m__all_brand_image {
  //height:100px;
  max-height: 100px;
  max-width: 150px;
}

.brand-brand-index .m__all_brand_label {
  text-align: center;
}

.brand-brand-index .m__all_brand_image_wrapper {
  height: 100px;
  vertical-align: middle;
  display: table-cell;
}
